import { useOnce } from "./useOnce";


export default function useTrustedFormAndLeadId() {
  const callLeadidAndTrustedForm = () => {
		var leadIdTriesLeft = 5;
		var trustedFormTriesLeft = 5;
		function loadLeadid() {
			let leadidTokenInput = document.getElementById('leadid_token');
			if (!leadidTokenInput) return;
			if (leadidTokenInput?.value && leadidTokenInput.value.trim() && !(/^(.)\1+$/.test(leadidTokenInput.value.trim().replace(/-/g, ''))))
				return;
			if (window.LeadiD && window.LeadiD.token && !(/^(.)\1+$/.test(window.LeadiD.token.replace(/-/g, '')))) {
				leadidTokenInput.value = window.LeadiD.token;
				return;
			}
			if (window.LeadiD && !window.LeadiD.token) {
				window.setTimeout(loadLeadid, 200);
				return;
			}
			if (leadIdTriesLeft <= 0)
				return;
			leadIdTriesLeft--;
			var s = document.createElement('script');
			s.id = 'LeadiDscript_campaign';
			s.type = 'text/javascript';
			s.async = true;
			s.onload = function () { window.setTimeout(loadLeadid, 500); };
			s.onerror = function () { window.setTimeout(loadLeadid, 500); };
			s.src = '//create.lidstatic.com/campaign/db726670-d7c7-48a2-6a47-74cdbb3a56aa.js?snippet_version=2';
			var LeadiDscript = document.getElementById('LeadiDscript');
			LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
		}
		function loadTrustedform() {
			var inputElement = document.querySelector('[name=xxTrustedFormToken]');
			if (inputElement && inputElement.value && inputElement.value.trim())
				return;
			if (trustedFormTriesLeft <= 0)
				return;
			trustedFormTriesLeft--;
			var field = 'xxTrustedFormCertUrl';
			var provideReferrer = false;
			var invertFieldSensitivity = false;
			var tf = document.createElement('script');
			tf.onload = function () { window.setTimeout(loadTrustedform, 500); };
			tf.onerror = function () { window.setTimeout(loadTrustedform, 500); };
			tf.type = 'text/javascript'; tf.async = true;
			tf.src = 'http' + ('https:' === document.location.protocol ? 's' : '') +
				'://api.trustedform.com/trustedform.js?field=' + escape(field) + '&provide_referrer=' + escape(provideReferrer) + '&l=' + new Date().getTime() + Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
			let s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
		}

		loadLeadid();
		loadTrustedform();
	};

	useOnce(() => {
		callLeadidAndTrustedForm();
  });
}